.Loading {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}
#mainLogo{
    width: 100vw;
    height: 100vh;
}
#canvas{
    transform-origin: 0 0;
    transition: transform 3s 1s ease-in-out, opacity 2s 4s ease-out;
}
.remove {
    transform: translateY(-4000px);
    opacity: 0;
}
.About-Header {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 50px;
    color: white;
}
.image-container{
    flex: 1 0 45%;
    box-sizing: border-box;
    padding: 1em;
}
.About-image{
    width: 100%;
    border-radius: 50%;
    box-shadow: 1px 1px 5px black;
    animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.About-text{
    font-size: 3vw;
    flex-grow: 1;
    padding: 0 1em;
    text-align: justify;
    color: white;
    box-sizing: border-box;
}
.About-text a{
    color: white;
    text-decoration: none;
    transition: color 500ms ease-in
}
.About-text a:hover{
    color: #F30752
}

@media screen and (max-width: 800px){
    .image-container{
        width: 95%;
        margin: 0;
        padding: 0;
    }
    .About-text{
        font-size: 3vh;
    }
}

@media screen and (max-width: 800px) and (orientation: landscape){
    .About-text{
        font-size: 7vh;
    }
}
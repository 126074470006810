.socials {
position: fixed;
box-sizing: border-box;
bottom: 2%;
right: 5%;
z-index: 5;
color: white;
display: flex;
height: 10%;
justify-content: flex-end;
align-items: center;
}
.socials a {
padding: 5px;
text-decoration: none;
color: white;
border-radius: 50%;
background-color: black;
}
.socials a:hover{
    transform: scale(1.1);
}
.socials a:active{
    transform: scale(.9);
}

.socials .fa-twitter {
color: #1DA1F3;

}

.socials .fa-linkedin {
color: #0A66C2;
}

footer{
    background-color: black;
    color: white;
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
address {
    text-align: center;
    font-size: 3vw;
}

address a{
    color: white;
    text-decoration: none;
}

address a:hover{
    color:#5f9eae;
}

@media only screen and (max-width:650px){
    address{
        font-size: 2vh;
    }
    .socials{
        font-size: 10px;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}
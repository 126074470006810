:root{
  margin: 0;
  padding: 0;
  --primary-color:#5f9eae;
  --secondary-color: #afeeff;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
}
.umbrella-container{
  display: none;
}
body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.overflow-hidden{
  overflow-y:hidden;
}
.home {
  margin:0;
  padding: 0;
  opacity: 1;
  transition: opacity 5s ease-in, transform 5s ease-out;
}
.hide{
  opacity: 0;
}
header {
  border-bottom: 4px solid var(--primary-color);
  position: sticky;
  top:0;
  left: 0;
  box-sizing: border-box;
  background-color: white;
  z-index: 1;
}
header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
header nav .nav-links {
  display: flex;
  margin: 0;
  padding: 0;
  transition: height 750ms ease-in-out;
}
header nav ul li {
  display: flex;
  padding: .5em 1em;
  list-style: none;
  align-items: center;
  transform-origin: top;
}
header nav ul a {
  text-decoration: none;
  color: Black;
  padding: 0 .25em;
  font-size: 1.5vw;
  display: flex;
  align-items: center;
}
main{
  box-sizing: border-box;
}
.links{
  position: relative;
  transition: transform 1s ease-in-out;
}
header nav ul a:hover .links{
  transform: rotate(90deg);
}
.nav-link a {
  font-size: 20px;
}
.nav-header {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0.5em;
}
.nav-menu h4{
  display: none;
}
#nav-button{
  display: none;
  align-self: flex-end;
}
main {
  background-color: var(--primary-color);
  padding: 0 5%;
}
.Intro{
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 25px 0px;
}
.Intro-image {
  width: 50%;
  height: 40vw;
  position: relative;
  z-index: 0;
  background-image: url("./Profile_Pic.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-left: 1em;
}
.Intro-text{
  font-family: "Work Sans", sans-serif;
  font-size: 28px;
  width: 50%;
  color: white;
  text-align: center;
}
.section-title{
  width: 100%;
  color: white;
  font-size: 28px;
  text-align: center;
}
.section{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.section-image{
  box-sizing: border-box;
  flex: 1 0 50%;
}
.section-text{
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.section-text h2{
  font-size: 40px;
  text-align: center;
}
.section-text p{
  width: 75%;
  font-size: 2vw;
  text-align: justify;
}
#Performance {
  transform-origin: 400px 600px;
  animation: loading 5s ease-in-out forwards infinite;
}
#loadBar{
  transform-origin: left;
  animation: grow 5s ease-in-out forwards infinite;
}
 #X{
   font-size: 500px;
   animation: show 5s forwards infinite;
   opacity: 0;
 }
 #check{
   font-size: 500px;
   animation: show 5s 2.5s forwards infinite;
   opacity: 0;
 }
.btn {
  padding: 1em;
  width: 50%;
  border: 2px solid black;
  border-radius: 18px;
  font-size: 20px;
  background-color: #afeeff;
}
.btn a{
  text-decoration: none;
  color: #3f7e8e;
}
@media screen and (max-width:800px){
  .section{
    flex-direction: column;
  }
  .section-text p{
    text-align: center;
    width: 100%;
    font-size: 4vh;
  }
  .section:nth-of-type(2n){
    flex-direction: column-reverse;
  }
  .Intro{
    flex-direction: column;
    padding: 1em 0;
  }
  .Intro-image{
    width: 90%;
    height: 300px;
  }
  .HELP{
    width: 100%;
    font-size: 5vw;
    text-align: center;
  }
  .nav-header{
    width: 100%;
  }
  header{
    margin: 0;
    padding: 10px 10px 0 10px;
    width: 100vw;
  }
  header nav {
    width: 100%;
    position: relative;
    flex-direction: column;
  }
  .links{
    width: 50px;
    height: 50px;
  }
  .nav-links{
    display: flex;
    width: 250px;
    position: absolute;
    right: -20px;
    z-index: 1;
    top: 61px;
    align-self: flex-end;
    flex-direction: column;
    background-color: white;
    height: 0;
    overflow: hidden;
    transition: height 750ms ease-in-out;
  }
  .nav-links-active {
    height: 350px;
  }
  .nav-menu{
    padding-right: .5em;
  }
  .nav-menu h4{
    display: block;
  }
  #nav-button{
    display: block;
    cursor: pointer;
  }
  .nav-button-active{
    transform: rotate(180deg);
  }
}
#before{
  font-size: 200px;
  fill: white;
  animation: switch 5s forwards infinite;
}
#after{
  font-size: 200px;
  fill: white;
  animation: switch 5s 2.5s forwards infinite;
  opacity: 0;
}
#vs {
  font-size: 100px;
}
#talk-orange{
  animation: talk 3s forwards infinite;
}
#talk-red{
  animation: talk2 3s 1.5s forwards infinite;
  transform:translate(50%, 35%)
}
#talk1{
  transform:translate(600px, 150px);
  animation: switch 3s 1.5s forwards infinite;
  opacity: 0;
}
#talk2{
  transform:translate(800px, 150px);
  animation: switch 3s forwards infinite;
}
button{
  background: none;
  border: 4px solid white;
  border-radius: 10px;
  font-size: 30px;
  font-weight: 500;
  color: white;
  font-family: "Work Sans", sans-serif;
  cursor: pointer;
  outline: none;
}
button:hover{
  transform:scale(1.1) translate(1px, 1px);
}
button:active{
  transform: scale(.9) translate(-1px, -1px);
}
button:focus{
  outline: none;
}

.skills {
  flex: 1 1 400px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}
.skills h1 {
  color: white;
  width: 100%;
}
.skills span {
  flex: 1 1 30%;
}
.fab {
  padding: 1rem;
  filter: drop-shadow(1px 1px 2px black);
}
.fa-html5 {
  color: #FD7E14;
}
.fa-css3-alt {
  color: #1579B8;
}
.fa-js{
  color: #EFD81D;
}
.fa-node-js{
  color: #58A644;
}
.fa-python{
  color: #3276AE;
}
.fa-react{
  color: #00D1F7;
}
.fa-bootstrap{
  color: #8C12FD;
}

.Projects{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 75vh;
}
main h1{
  margin: 0;
  padding: 0;
}
.Body-Header{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 35px;
  color: white;
  text-decoration: underline;
}
.Projects{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 5%;
}

.projectblock {
color: white;
position: relative;
display: flex;
flex-direction: column;
box-sizing: border-box;
flex: 0 0 25%;
padding: 1%;
align-items: center;
justify-content: flex-start;
}
.projectblock h3 {
  font-size: 20px;
  padding: 0;
  margin: 0;
  text-align: center;
}
.projectblock a {
  color: white;
  text-decoration: none;
}
.Websites {
  font-size: 25px;
  border: 4px solid white;
  border-radius: 15px;
  width: 100%;
  text-align: center;
  margin: 4px 0;
}
.Githubs {
  font-size: 25px;
  border: 4px solid white;
  width: 100%;
  text-align: center;
  border-radius: 15px;
    margin: 4px 0;
}
.form-container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

#contact {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-row {
  width: 50%;
  border-radius: 10px;
  font-size: 20px;
  margin: 2px;
  text-align: center;
  padding: .5vh;
}

.form-row-button {
  margin: 2px;
  font-size: 25px;
  width: 40%;
  border-radius: 20px;
  color: white;
}
@media only screen and (max-width: 750px){
  .Projects{
    flex-direction: column;
    height: fit-content;
  }
  .projectblock{
    padding-top: 1em;
    width: 100%;
    align-items: center;
  }
  .form-row{
    width: 95%;
  }
}
@keyframes loading{
  0%{
    transform:translate(0, 500px);
  }
  40%{
    transform:translate(1500px, 500px);

  }
  41%{
    transform: translate(1500px, 500px) rotate(60deg);
  }
  45%{
    transform: translate(1500px, 500px) rotate(160deg);
  }
  50%{
    transform: translate(1500px, 500px) rotate(150deg);
  }
  59%{
    transform: translate(1500px, 500px) rotate(150deg);
  }
  60%{
    transform: translate(0, 500px) rotate(0);
  }
  80%{
    transform:translate(1950px, 500px);
  }
  100%{
    transform:translate(1950px, 500px);
  }
}

@keyframes grow{
  0%{
    width: 0;
  }
  40%{
    width: 1500px;
    fill: green
  }
  41%{
    fill: red;
  }
  59%{
    fill: red;
    width: 1500px;
  }
  60%{
    fill: green;
    width: 0;
  }
  80%{
    width: 2250px;
  }
  100%{
    width: 2250px;
  }
}

@keyframes show{
  0%{
    opacity: 0;
  }
  40%{
    opacity: 0;
  }
  41%{
    opacity: 1;
  }
  59%{
    opacity: 1;
  }
  60%{
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}

@keyframes switch{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 1;
  }
  51%{
    opacity: 0;
  }
100%{
  opacity: 0;
}
}


@keyframes talk {
  0%{
    transform:translate(500px, 350px);
  }
  10%{
    transform:translate(500px, 300px) rotate(5deg);
  }
  20%{
    transform:translate(500px, 350px);
  }
  30%{
    transform:translate(500px, 300px) rotate(-5deg);
 }
  40%{
    transform:translate(500px, 350px);
  }
  50%{
    transform:translate(500px, 300px) rotate(5deg);;
  }
  55%{
    transform:translate(500px, 350px);
  }
  100%{
    transform:translate(500px, 350px);
  }
  
}
@keyframes talk2 {
  0%{
    transform:translate(1000px, 350px);
  }
  10%{
    transform:translate(1000px, 300px) rotate(-5deg);
  }
  20%{
    transform:translate(1000px, 350px);
  }
  30%{
    transform:translate(1000px, 300px) rotate(5deg);
  }
  40%{
    transform:translate(1000px, 350px);
  }
  50%{
    transform:translate(1000px, 300px) rotate(-5deg);
  }
  55%{
    transform:translate(1000px, 350px);
  }
  100%{
    transform:translate(1000px, 350px);
  }
  
}
.resume-main{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
}

.resume-section{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.resume-section h3{
  text-decoration: underline;
  margin: 0;
  padding: 0;
}

.resume-list{
  color: white;
  width: 50%;
  font-size: 20px;
  padding: .5em;
  text-align: center;
}

.resume-list h4{
  text-decoration: underline;
  margin: 0;
}
.resume-list ul{
  padding: 0;
  margin: 0;
}
.resume-list li{
  list-style: none;
}

@media only screen and (max-width:750px){
  body{
    overflow-x:hidden;
  }
  .resume-main{
    flex-direction: column;
    align-items: center;
  }
  .resume-section{
    width: 100%;
  }
  .resume-list{
    width: 90%;
  }
  .Intro-text {
    width: 100%;
  }
}

.portfolio-image{
  border-radius: 10px;
}

.modal{
  opacity: 0;
  position:absolute;
  z-index: 3;
  border: 5px solid white;
  background: var(--primary-color);
  border-radius: 10px;
  right: 50%;
  width: 100%;
  transition: opacity 300ms ease-in-out;
  text-align: justify;
  padding: 1em;
  display: none;
}

.modal-show{
  display: block;
  opacity: 1;
}

.modal a{
  color: blue;
}
.min-project{
  display: none;
}
@media only screen and (max-width: 800px){

  .modal{
    width: 90%;
    top: 10%;
    left: 5%;
    box-sizing: border-box;
  }
  .modal-show{
    display: none;
  }
  .min-project{
    display: block;
    font-size: 25px;
  border: 4px solid white;
  border-radius: 15px;
  width: 100%;
  text-align: center;
  margin: 4px 0;
  }
  .min-modal-show{
    display: block;
    opacity: 1;
  }
}